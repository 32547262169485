import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Layout, RandomOp, Typography } from '../../components';
import {
  MainTitle, MainPart, Figure, List,
} from '../../components/opComponents';

const RuptureDuTendonDuBicepsAuCoude = () => (
  <Layout
    title="Diagnostic et traitement - Dr Ghazanfari"
    description="Le plus souvent, la rupture du tendon du biceps au coude survient sur un tendon fragilisé avec le temps, après un effort brutal ou anodin."
    headerStyle="white"
  >
    <div className="flex flex-col px-4 py-16 mx-auto space-y-4 max-w-screen-mdx">
      <Link to="/chirurgie-du-coude" className="flex flex-row items-center space-x-3 uppercase text-primary group">
        <FontAwesomeIcon className="transition-all duration-300 ease-in-out group-hover:text-link" icon="long-arrow-alt-left" />
        <Typography className="group-hover:text-link" weight="semiBold" transition="medium">Retour aux opérations du coude</Typography>
      </Link>
      <div className="flex flex-col space-y-6">
        <MainTitle currentOpTitle="Rupture du tendon du biceps au coude" />
        <MainPart title="Qu'est-ce qu'une rupture du tendon du biceps du coude ?">
          <Typography weight="bold">
            La rupture du tendon du biceps au coude
            {' '}
            <Typography variant="span">
              est une rupture de l&apos;insertion distale du biceps
              au niveau de son insertion sur la tubérosité radiale.
            </Typography>
          </Typography>
        </MainPart>
        <MainPart title="Quels sont les chefs musculaires qui composent le biceps ?">
          <Typography>
            Le biceps a essentiellement un rôle de flexion du coude
            et de supination de l&apos;avant-bras.
          </Typography>
          <Typography>
            Il possède deux chefs musculaires : le court et le long biceps.
            Le court biceps s&apos;insère
            sur l&apos;apophyse coracoïde et le long biceps sur la partie supérieure de la glène de
            l&apos;omoplate. Ces deux chefs présentent une insertion distale
            commune sur la tubérosité
            radiale, au niveau du coude (Figure 1).
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure1-rupturedutendondubicepsaucoude.jpg"
            caption="Figure 1. Anatomie du biceps"
            currentOpTitle="Rupture du tendon du biceps au coude"
          />
        </MainPart>
        <MainPart title="Quelles sont les causes possibles d'une rupture du tendon du biceps ?">
          <Typography>
            Le plus souvent la rupture survient sur un tendon fragilisé avec le temps,
            après un effort brutal ou parfois anodin. Les facteurs favorisant la rupture sont :
            {' '}
            <Typography variant="span" weight="bold">le sexe (masculin dans 92% des cas), l&apos;âge (à partir de 40 ans), le tabac et la consommation de stéroïdes anabolisants.</Typography>
          </Typography>
        </MainPart>
        <MainPart title="Comment diagnostiquer une rupture du tendon du biceps ?">
          <Typography>
            Le diagnostic est clinique. On observe le plus souvent un gonflement ou un hématome
            au niveau du coude. Par rapport au coté sain, le biceps est ascensionné (Figure 2)
            et la force en flexion et en supination est diminuée. On peut parfois observer
            des fourmillements sur le bord latéral de l&apos;avant-bras, en rapport avec une
            irritation du nerf cutané latéral de l&apos;avant-bras (Bassett & Nunley Syndrom).
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure2-rupturedutendondubicepsaucoude.jpg"
            caption="Figure 2. Rupture complète du biceps gauche se traduisant par une ascension de son corps musculaire"
            currentOpTitle="Rupture du tendon du biceps au coude"
          />
        </MainPart>
        <MainPart title="Quels sont les examens qui permettent de confirmer une rupture du tendon du biceps ?">
          <Typography>
            L&apos;imagerie permet de conforter le diagnostic. L&apos;Echographie met en évidence la
            rupture dans la majorité des cas mais reste un examen opérateur dépendant.
            L&apos;IRM (incidence de FABS) est le meilleur examen pour voir la rupture
            et apprécier sa réductibilité (Figure 3).
          </Typography>
          <Figure
            imageName="figure3-rupturedutendondubicepsaucoude.jpg"
            caption="Figure 3. Cliché d&apos;IRM montrant une rupture complète de l&apos;insertion distale du biceps au coude (flèche blanche)"
            currentOpTitle="Rupture du tendon du biceps au coude"
          />
        </MainPart>
        <MainPart title="Quel est le traitement en cas de rupture du tendon du biceps au coude ?">
          <Typography>
            L&apos;opération n&apos;est pas une obligation mais l&apos;abstention
            thérapeutique a plusieurs
            inconvénients : perte de force en flexion et supination (jusqu&apos;à 50%), douleurs
            chroniques du bras en raison de la rétraction tendineuse, fourmillements
            chroniques du bord latéral de l&apos;avant-bras et séquelles esthétiques.
          </Typography>
          <Typography>
            L&apos;opération doit être effectuée dans les 2 semaines suivant le traumatisme.
            Au delà de ce délais, la réinsertion du tendon du biceps sur la tubérosité
            radiale risque de ne pas être possible en raison de l&apos;importance de la rétraction
            tendineuse. Le tendon du biceps sera alors suturé au tendon du muscle
            adjacent : le brachial antérieur.
          </Typography>
          <Typography>
            Dans les ruptures récentes, une incision de 3-4cm est faite
            sous le pli du coude afin de récupérer le tendon du biceps (Figure 4).
          </Typography>
          <Figure
            maxWidth="max-w-[150px]"
            imageName="figure4-rupturedutendondubicepsaucoude.jpg"
            caption="Figure 4. Incision cutanée permettant de récupérer le tendon du biceps"
            currentOpTitle="Rupture du tendon du biceps au coude"
          />
          <Typography>
            En général, deux ancres sont mises en place dans le radius,
            permettant une fixation solide du tendon sur l&apos;os (Figures 5 et 6).
          </Typography>
          <div className="flex flex-col items-start space-x-0 space-y-8 mdx:flex-row mdx:space-x-8 mdx:space-y-0">
            <Figure
              maxWidth="max-w-[200px]"
              imageName="figure5-rupturedutendondubicepsaucoude.jpg"
              caption="Figure 5. Mise en place de deux ancres dans la tubérosité radiale permettant ainsi la réinsertion du tendon"
              currentOpTitle="Rupture du tendon du biceps au coude"
            />
            <Figure
              maxWidth="max-w-[400px]"
              imageName="figure6-rupturedutendondubicepsaucoude.jpg"
              caption="Figure 6. Radiographies du coude mettant en évidence le bon positionnement des ancres"
              currentOpTitle="Rupture du tendon du biceps au coude"
            />
          </div>
        </MainPart>
        <MainPart title="Quelles sont les suites posts-opératoires après une opération chirurgicale pour une rupture du tendon du biceps ?">
          <Typography>
            La cicatrisation cutané est obtenue au bout de deux semaines. Le coude
            est immobilisé en flexion à 90° par une attelle laissant la main
            libre, pendant six semaines avant de débuter la rééducation (Figure 7).
          </Typography>
          <Figure
            maxWidth="max-w-[400px]"
            imageName="figure7-rupturedutendondubicepsaucoude.jpg"
            caption="Figure 7. Attelle d&apos;immobilisation post-opératoire du coude"
            currentOpTitle="Rupture du tendon du biceps au coude"
          />
          <Typography>
            A l&apos;ablation de l&apos;attelle, il existe souvent une fonte musculaire qui
            mettra plusieurs mois à récupérer. Le renforcement musculaire sera
            autorisé à partir du 3ème mois après l&apos;opération.
          </Typography>
        </MainPart>
        <MainPart title="Quelles sont les complications possibles après une opération chirurgicale pour une rupture du tendon du biceps ?">
          <Typography size="mdx" weight="bold">Complications non spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Infection du site opératoire pouvant nécessiter une
                intervention de nettoyage associée à la prise d&apos;antibiotiques
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Hématome pouvant nécessiter une évacuation en cas
                de menace cutanée ou compression nerveuse
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Algodystrophie. Son apparition est indépendante du type de chirurgie.
                Elle évolue en deux phases : phase chaude (main gonflée, douloureuse
                avec transpiration) puis froide (prédominance de la raideur). L&apos;évolution
                est le plus souvent longue (12-18 mois) et des séquelles sont
                possibles (douleur et/ou raideur des articulations des
                doigts et/ou poignet et/ou épaule)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Accidents liés à l&apos;anesthésie
              </Typography>
            </List.Item>
          </List>
          <Typography size="mdx" weight="bold">Complications spécifiques :</Typography>
          <List>
            <List.Item>
              <Typography>
                Nerveuse (nerf radial en particulier)
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Perte de l&apos;extension complète en cas de rétraction importante du tendon
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Crampes du biceps spontanément résolutives dans la majorité des cas
              </Typography>
            </List.Item>
            <List.Item>
              <Typography>
                Récupération incomplète de la force et du volume musculaire
              </Typography>
            </List.Item>
          </List>
        </MainPart>
      </div>
    </div>
    <RandomOp type="coude" />
  </Layout>
);

export default RuptureDuTendonDuBicepsAuCoude;
